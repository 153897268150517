<template>
  <div v-if="checkPrivilege('report:read')">
    <base-dialog
      ref="dialogIssueTaxForm"
      max-width="500"
      :title="'納付書のダウンロード'"
      :message="'源泉所得税の納付書を発行します。'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'発行'"
      @submit="issueTaxForm()"
    >
      <template v-slot:card-text>
        <div class="py-4">源泉所得税の納付書を発行します。</div>
        <v-select
          v-model="issueTaxInfo.paymentMonth"
          label="報酬支払月"
          :items="issueTaxInfo.yearMonthItems"
          item-text="text"
          item-value="value"
          class="pt-3"
          dense
          outlined
          filled
          style="white-space: nowrap; max-width: 70%"
          placeholder="支払月を教えてください"
          :rules="[(v) => !!v || '必ず入力ください']"
        />
      </template>
    </base-dialog>

    <v-container fluid py-0 px-0>
      <v-layout>
        <div class="w-100">
          <v-layout row />
          <div>
            <report-table
              :data_by_issue="data_by_issue"
              :data_by_pay="data_by_pay"
              :date_key_list="date_key_list"
              :load_table_data="load_table_data"
              :get-tables-data="getTablesData"
              :open-tax-form-dialog="openTaxFormDialog"
              :style="{ width: '100vw - 54px' }"
            />
          </div>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ReportTable from './@components/ReportTable'
import Vue from 'vue'
import Axios from 'axios'

export default {
  components: {
    ReportTable,
  },
  props: {
    organization: null,
    user: null,
  },
  data() {
    return {
      load_table_data: 0,
      date_key_list: [],
      data_by_issue: [],
      data_by_pay: [],
      searchForm: {
        company_name: '',
        summary: '',
        amount_1: '',
        amount_2: '',
        id_1: '',
        id_2: '',
        debit_amount: 0,
        credit_amount: 0,
        confirm: '',
        approve: '',
        debit_sub_account_list: [],
        department_list: [],
        debit_account_item: '',
        debit_sub_account_name: '',
        debit_department_name: '',
        credit_account_item: '',
        credit_sub_account_list: [],
        credit_sub_account_name: '',
        credit_department_name: '',
        date: '',
        to_date: '',
        search_bar: '',
        reading_start_date: '',
        reading_to_date: '',
      },
      filters: {
        company_name: '',
        note_1: '',
        confirm: '',
        approve: '',
        debit_account_item: '',
        debit_sub_account_name: '',
        debit_department_name: '',
        credit_account_item: '',
        credit_sub_account_name: '',
        credit_department_name: '',
        date: '',
        amount_1: '',
        amount_2: '',
        id_1: '',
        id_2: '',
        to_date: '',
        search_bar: '',
        reading_date: '',
        reading_to_date: '',
      },
      dialog: {
        issueTaxForm: false,
      },
      issueTaxInfo: {
        paymentMonth: '',
        taxFormType: 1,
        yearMonthItems: [],
      },
      detail_journal_list: '',
      showExported: false,
    }
  },
  created() {
    this.getTablesData()
  },
  methods: {
    getTablesData() {
      try {
        this.$store.dispatch('getInvoiceReport').then((data) => {
          setTimeout(() => {
            this.data_by_issue = data.data_by_issue
            this.data_by_pay = data.data_by_pay
            this.date_key_list = data.date_key_list
            this.load_table_data += 1
            if (this.$route.params.id) {
              Vue.nextTick(() => {
                var el = $('.selected-element').position()
                if (el) {
                  $('.invoice-sidebar-scroll').scrollTop(el.top)
                }
              })
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    filterAllSearchBar() {
      try {
        let value = this.searchForm.search_bar
        this.filters = this.$MultiFilters.updateFilters(this.filters, {
          search_bar: value,
        })
      } catch (e) {
        console.log(e)
      }
    },
    issueTaxForm() {
      Axios.get(this.$store.getters.apiIssueTaxFormUrl, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        responseType: 'blob',
        params: {
          organization_id: this.$store.getters.getOrganizationID,
          form_type: this.issueTaxInfo.taxFormType,
          pay_month: this.issueTaxInfo.paymentMonth,
        },
      }).then((response) => {
        let file = new Blob([response.data], { type: 'application/pdf' })
        let url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'gensen_reward_tax.pdf')
        document.body.appendChild(link)
        link.click()

        this.$refs.dialogIssueTaxForm.close()
      })
    },
    getYearMonthItems(yyyymm) {
      try {
        let date = []
        let now = new Date(yyyymm)
        let date_ = new Date(now.setMonth(now.getMonth() - 1))
        for (let i = 1; i < 13; i++) {
          date_ = new Date(now.setMonth(now.getMonth() + 1))
          let year_month_value =
            date_.getMonth() >= 9
              ? `${date_.getFullYear()}-${date_.getMonth() + 1}`
              : `${date_.getFullYear()}-0${date_.getMonth() + 1}`
          let year_month_text = year_month_value.replace('-', '年').concat('月')
          date.push({ text: year_month_text, value: year_month_value })
        }
        return date
      } catch (e) {
        console.log(e)
      }
    },
    openTaxFormDialog(year, month) {
      try {
        let month_ =
          month.length > 2 ? month.slice(0, -1) : `0${month.slice(0, -1)}`
        this.issueTaxInfo.paymentMonth = year.replace('年', '-') + month_
        this.issueTaxInfo.yearMonthItems = this.getYearMonthItems(
          this.issueTaxInfo.paymentMonth
        )
        this.$refs.dialogIssueTaxForm.open()
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
