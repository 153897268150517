<template>
  <div class="w-100">
    <v-dialog v-model="dialog_export_report" max-width="500">
      <v-card class="sweeep-dialog">
        <v-card-title v-text="$t('message.invoice_report_export')" />
        <v-card-text>
          <v-continer>
            <v-row class="px-4 pt-4">
              <v-col cols="3">
                <div class="fs-12 pt-2">フォーマット</div>
              </v-col>
              <v-col cols="9">
                <v-autocomplete
                  v-model="selected_format"
                  :items="format_list"
                  label="ファイルフォーマット"
                  single-line
                  dense
                  outlined
                  filled
                  placeholder="ファイルフォーマット"
                  class="small-input"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
          </v-continer>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click.native="
              dialog_export_report = false
              export_loading = false
            "
          >
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn v-if="!export_loading" color="primary" @click="downloadCSV()">
            {{ $t('message.invoice_table_export') }}
          </v-btn>
          <v-btn v-else loading color="primary" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <app-section-loader :status="loader" />
    <v-toolbar height="50" color="white">
      <!-- 表示年 -->
      <span class="fs-10 mr-2">集計開始年月</span>
      <v-autocomplete
        v-model="display_year"
        :items="year_list"
        single-line
        outline
        placeholder="年"
        class="flat-select-box"
        style="max-width: 100px"
        @change="display_date_changed += 1"
      />
      <!-- 開始月 -->
      <v-autocomplete
        v-model="display_month"
        :items="month_list"
        single-line
        outline
        placeholder="月"
        class="flat-select-box ml-2"
        style="max-width: 88px"
        @change="display_date_changed += 1"
      />

      <v-autocomplete
        v-model="date_type"
        v-tooltip="
          '費用計上日または支払日をベースに集計できます<br>支払日で集計：支払日が未指定のデータは集計されません'
        "
        :items="date_type_list"
        item-text="name"
        item-value="id"
        single-line
        outline
        class="flat-select-box ml-4"
        style="max-width: 150px"
      />

      <v-autocomplete
        v-model="amount_type"
        v-tooltip="
          'メインで集計する金額の種類を指定できます<br>＋ボタンで展開すると他の金額も閲覧可能です'
        "
        :items="amount_type_list"
        item-text="name"
        item-value="value"
        single-line
        outline
        class="flat-select-box ml-4"
        style="max-width: 130px"
      />

      <v-btn
        v-tooltip="'金額の詳細を展開します'"
        class="fs-12 ml-5 mr-4"
        small
        @click.native.stop="openAllJournal()"
      >
        <i class="ti-layout-accordion-list mr-2" />
        全て展開
      </v-btn>
      <v-btn
        v-tooltip="'金額の詳細を折りたたみます'"
        class="fs-12"
        small
        @click.native.stop="closeAllJournal()"
      >
        <i class="ti-view-list-alt mr-2" />
        全て折り畳む
      </v-btn>

      <v-spacer />

      <v-text-field
        :value="search"
        append-icon="search"
        label="請求元"
        single-line
        hide-details
        clearable
        class="pt-0 px-3 flat-select-box"
        @input="search = $event ? $event : ''"
      />
      <v-btn
        v-if="checkPrivilege('report:export')"
        v-tooltip="'報酬・料金等の源泉税（士業除く）の納付書を発行します。'"
        small
        class="fs-12 ml-4"
        @click="openTaxFormDialog(display_year, display_month)"
      >
        <i class="ti-file mr-4 mr-2" />
        源泉税納付書
      </v-btn>
      <v-btn
        v-if="checkPrivilege('report:export')"
        class="px-3 ml-4 fs-12"
        small
        @click.native.stop="dialog_export_report = true"
      >
        <i class="ti-export mr-4" />
        エクスポート
      </v-btn>
    </v-toolbar>

    <div row wrap class="px-3 pt-3">
      <v-data-table
        v-model="selected"
        :headers="computedHeaders"
        :items="computedData"
        item-key="id"
        hide-default-header
        :footer-props="footerProps"
        class="fs-10"
        fixed-header
        height="calc(100vh - 180px)"
      >
        <template #header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="header in headers" :key="'header_' + header.text">
                <span v-if="'total_max_amount' in header">
                  <div class="text-right">
                    {{ header.text }}<br />
                    <span class="fs-10" style="color: #aaa">月計</span>
                    <span style="color: #4b78d0">
                      {{
                        header['total_' + selectedAmountType.value] | currency
                      }}
                    </span>
                  </div>
                </span>
                <span v-else>
                  {{ header.text }}
                </span>
              </th>
            </tr>
          </thead>
        </template>

        <template #body="{ items }">
          <tbody>
            <template v-for="(item, companyIndex) in items">
              <tr>
                <td style="min-width: 250px">
                  <v-btn
                    v-tooltip="'詳細金額を表示／非表示'"
                    color="primary"
                    icon
                    class="mini"
                    style
                    @click="showAllAmount(item)"
                  >
                    <s-icon
                      :icon="item.showAll ? 'feather-minus' : 'feather-plus'"
                      color="current"
                      size="xl"
                    />
                  </v-btn>
                  {{ item.company_name }}
                </td>

                <template>
                  <td
                    v-if="headers[1].display"
                    :key="companyIndex + '_row_' + selectedAmountType.value"
                    style="min-width: 70px"
                  >
                    {{ selectedAmountType.name }}
                  </td>
                  <template v-for="(dateKey, i) in date_key_list">
                    <td
                      v-if="headers[2 + i].display"
                      :key="
                        companyIndex +
                        '_col_' +
                        dateKey +
                        '_' +
                        selectedAmountType.value
                      "
                      class="text-right"
                    >
                      <span
                        :class="[
                          item[dateKey][selectedAmountType.value] !== 0
                            ? 'has-link'
                            : '',
                        ]"
                        @click="
                          toInvoiceList(
                            item.company_name,
                            dateKey,
                            item[dateKey][selectedAmountType.value] === 0
                          )
                        "
                      >
                        {{ item[dateKey][selectedAmountType.value] | currency }}
                      </span>
                    </td>
                  </template>
                  <td
                    :key="companyIndex + '_total_' + selectedAmountType.value"
                    class="text-right"
                  >
                    {{ item['total_' + selectedAmountType.value] | currency }}
                  </td>
                </template>
              </tr>

              <template v-if="item.showAll">
                <template v-for="type in amount_type_list">
                  <tr
                    v-if="type.value != selectedAmountType.value"
                    :key="companyIndex + '_row_' + type.value"
                    style="background-color: #d7f0ff"
                  >
                    <td />
                    <td v-if="headers[1].display" style="min-width: 70px">
                      {{ type.name }}
                    </td>
                    <template v-for="(dateKey, i) in date_key_list">
                      <td
                        v-if="headers[2 + i].display"
                        :key="
                          companyIndex + '_col_' + dateKey + '_' + type.value
                        "
                        class="text-right"
                      >
                        <span
                          :class="[
                            item[dateKey][type.value] !== 0 ? 'has-link' : '',
                          ]"
                          @click="
                            toInvoiceList(
                              item.company_name,
                              dateKey,
                              item[dateKey][type.value] === 0
                            )
                          "
                        >
                          {{ item[dateKey][type.value] | currency }}
                        </span>
                      </td>
                    </template>
                    <td
                      :key="companyIndex + '_total_' + type.value"
                      class="text-right"
                    >
                      {{ item['total_' + type.value] | currency }}
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </template>

        <template #no-results>
          <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
            {{ $t('message.noResult') }}
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Encoding from 'encoding-japanese'
import Snackbar from 'Helpers/snackbar/index'
import { quoteCommaAndNewline } from 'Helpers/helpers'

export default {
  props: {
    getTablesData: Function,
    loader: null,
    data_by_issue: null,
    data_by_pay: null,
    date_key_list: null,
    load_table_data: null,
    openTaxFormDialog: Function,
  },
  data() {
    return {
      date_type: 1,
      date_type_list: [
        {
          id: 1,
          name: '伝票日付で集計',
        },
        {
          id: 2,
          name: '支払日付で集計',
        },
      ],
      search: '',
      display_settings_local: '',
      headers: [],
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [100, 200, 500, 1000],
      },
      selected: [],
      display_date_changed: 0,
      display_changed: 0,
      dialog_export_report: false,
      export_loading: false,
      selected_format: 'Shift-JIS',
      format_list: ['Shift-JIS', 'utf-8'],
      display_year: '',
      display_month: '',
      year_list: [],
      month_list: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      amount_type: 'max_amount',
      amount_type_list: [
        {
          value: 'max_amount',
          name: '税込額',
        },
        {
          value: 'tax_amount',
          name: '消費税額',
        },
        {
          value: 'gensen_reward_amount',
          name: '報酬額(源泉)',
        },
        {
          value: 'gensen_amount',
          name: '源泉税額',
        },
        {
          value: 'pay_amount',
          name: '支払額',
        },
        {
          value: 'unpaid_amount',
          name: '未払金額',
        },
      ],
    }
  },
  computed: {
    computedHeaders: {
      get: function () {
        return this.filterHeader()
      },
      set: function (newVal) {},
    },
    computedData: {
      get: function () {
        return this.filterData()
      },
      set: function (newVal) {},
    },
    selectedAmountType: function () {
      const selectedType = this.amount_type
      const selected = this.amount_type_list.find(
        (item) => item.value == selectedType
      )
      return selected
    },
  },
  watch: {
    load_table_data: function () {
      this.setUpHeader()
    },
    display_date_changed: function () {
      this.change_display_setting()
    },
    display_changed: function () {
      this.getTotalAmount()
    },
    display_year: function (year) {
      localStorage.setItem('invoiceReportYear', year)
    },
    display_month: function (month) {
      localStorage.setItem('invoiceReportMonth', month)
    },
  },
  mounted() {},
  methods: {
    downloadCSV() {
      this.export_loading = true
      var csvString = ''
      let dateKeys = []
      this.computedHeaders.forEach((header, i) => {
        csvString += header['text'] + ','
        if ([0, 1, 14].indexOf(i) === -1) {
          //1,2,最終列以外の月を取得
          dateKeys.push(header.value.split('.')[0])
        }
      })
      csvString = csvString.slice(0, -1)
      csvString += '\n'
      this.computedData.forEach((el) => {
        let companyName = el.company_name.normalize()
        this.amount_type_list.forEach((v) => {
          csvString += quoteCommaAndNewline(companyName) + ','
          csvString += v.name + ','
          dateKeys.forEach((key) => {
            csvString += el[key][v.value] + ','
          })
          csvString += el['total_'.concat(v.value)]
          csvString += '\n'
        })
      })
      let a = document.createElement('a')
      if (this.selected_format === 'utf-8') {
        let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' })
        a.href = window.URL.createObjectURL(blob)
      } else {
        const uniArray = Encoding.stringToCode(csvString)
        const sjisArray = Encoding.convert(uniArray, {
          to: 'SJIS',
          from: 'UNICODE',
        })
        const unit8Array = new Uint8Array(sjisArray)
        let blob = new Blob([unit8Array], {
          type: 'text/csv;charset=Shift_JIS',
        })
        a.href = window.URL.createObjectURL(blob)
      }
      a.download = 'Report.csv'
      a.click()
      this.export_loading = false
      this.dialog_export_report = false
    },
    openAllJournal() {
      for (let item of this.data_by_issue) {
        item.showAll = true
      }
      for (let item of this.data_by_pay) {
        item.showAll = true
      }
    },
    closeAllJournal() {
      for (let item of this.data_by_issue) {
        item.showAll = false
      }
      for (let item of this.data_by_pay) {
        item.showAll = false
      }
    },
    filterHeader() {
      try {
        const data = this.computedData
        if (data.length !== 0) {
          let filteredHeader = this.headers.filter((h) => {
            try {
              return h.display == true
            } catch (e) {
              console.log(e)
            }
          })

          for (let i = 2; i < filteredHeader.length - 1; i++) {
            const date = filteredHeader[i].value.slice(0, 8)

            const max_amount_list = data.map(function (d) {
              return d[date].max_amount
            })
            const tax_amount_list = data.map(function (d) {
              return d[date].tax_amount
            })
            const gensen_reward_amount_list = data.map((d) => {
              return d[date].gensen_reward_amount
            })
            const gensen_amount_list = data.map(function (d) {
              return d[date].gensen_amount
            })
            const gensen_targets_list = data.map((d) => {
              return d[date].gensen_total_targets
            })
            const pay_amount_list = data.map(function (d) {
              return d[date].pay_amount
            })
            const unpaid_amount_list = data.map(function (d) {
              return d[date].unpaid_amount
            })

            const total_max_amount = max_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
            const total_tax_amount = tax_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
            const total_gensen_reward_amount = gensen_reward_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
            const total_gensen_amount = gensen_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
            const gensen_total_targets = gensen_targets_list.reduce(
              (total, v) => (total += v),
              0
            )
            const total_pay_amount = pay_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
            const total_unpaid_amount = unpaid_amount_list.reduce(
              (total, v) => (total += v),
              0
            )

            filteredHeader[i].total_max_amount = total_max_amount
            filteredHeader[i].total_tax_amount = total_tax_amount
            filteredHeader[i].total_gensen_reward_amount =
              total_gensen_reward_amount
            filteredHeader[i].total_gensen_amount = total_gensen_amount
            filteredHeader[i].total_gensen_targets = gensen_total_targets
            filteredHeader[i].total_pay_amount = total_pay_amount
            filteredHeader[i].total_unpaid_amount = total_unpaid_amount
          }

          const annual_max_amount_list = data.map(function (d) {
            return d.total_max_amount
          })
          const annual_tax_amount_list = data.map(function (d) {
            return d.total_tax_amount
          })
          const annual_gensen_reward_amount_list = data.map(function (d) {
            return d.total_gensen_reward_amount
          })
          const annual_gensen_amount_list = data.map(function (d) {
            return d.total_gensen_amount
          })
          const annual_pay_amount_list = data.map(function (d) {
            return d.total_pay_amount
          })
          const annual_unpaid_amount_list = data.map(function (d) {
            return d.total_unpaid_amount
          })

          const annual_total_max_amount = annual_max_amount_list.reduce(
            (total, v) => (total += v),
            0
          )
          const annual_total_tax_amount = annual_tax_amount_list.reduce(
            (total, v) => (total += v),
            0
          )
          const annual_total_gensen_reward_amount =
            annual_gensen_reward_amount_list.reduce(
              (total, v) => (total += v),
              0
            )
          const annual_total_gensen_amount = annual_gensen_amount_list.reduce(
            (total, v) => (total += v),
            0
          )
          const annual_total_pay_amount = annual_pay_amount_list.reduce(
            (total, v) => (total += v),
            0
          )
          const annual_total_unpaid_amount = annual_unpaid_amount_list.reduce(
            (total, v) => (total += v),
            0
          )

          filteredHeader[filteredHeader.length - 1].total_max_amount =
            annual_total_max_amount
          filteredHeader[filteredHeader.length - 1].total_tax_amount =
            annual_total_tax_amount
          filteredHeader[filteredHeader.length - 1].total_gensen_reward_amount =
            annual_total_gensen_reward_amount
          filteredHeader[filteredHeader.length - 1].total_gensen_amount =
            annual_total_gensen_amount
          filteredHeader[filteredHeader.length - 1].total_pay_amount =
            annual_total_pay_amount
          filteredHeader[filteredHeader.length - 1].total_unpaid_amount =
            annual_total_unpaid_amount

          return filteredHeader
        }
      } catch (e) {
        this.getTablesData()
      }
    },
    filterData() {
      try {
        if (this.date_type == 1) {
          if (this.data_by_issue) {
            var data_fil = this.data_by_issue.filter((d) => {
              try {
                return d.company_name.trim().includes(this.search.trim())
              } catch (err) {
                console.log(err)
              }
            })
            return data_fil
          }
        } else if (this.date_type == 2) {
          if (this.data_by_pay) {
            var data_fil = this.data_by_pay.filter((d) => {
              try {
                return d.company_name.trim().includes(this.search.trim())
              } catch (err) {
                console.log(err)
              }
            })
            return data_fil
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    showAllAmount(item) {
      try {
        if (!item.showAll) {
          item.showAll = true
        } else {
          item.showAll = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    setUpHeader() {
      try {
        this.display_year = localStorage.getItem('invoiceReportYear')
        if (!this.display_year || !this.display_year.includes('年')) {
          localStorage.setItem('invoiceReportYear', '2019年')
          this.display_year = localStorage.getItem('invoiceReportYear')
        }

        this.display_month = localStorage.getItem('invoiceReportMonth')
        if (!this.display_month || !this.display_month.includes('月')) {
          localStorage.setItem('invoiceReportMonth', '1月')
          this.display_month = localStorage.getItem('invoiceReportMonth')
        }

        this.headers = [
          {
            text: '請求元',
            value: 'company_name',
            display: true,
            sortable: false,
          },
          {
            text: '種類',
            value: 'amount_type',
            display: true,
            sortable: false,
          },
        ]
        var now = new Date()
        var now_year = now.getFullYear()

        for (let date_key of this.date_key_list) {
          var yyyy = date_key.slice(1, 5)
          var mm = date_key.slice(6, 8)
          var text = yyyy + '年' + mm + '月'
          var value = date_key + '.max_amount'
          this.headers.push({
            text: text,
            value: value,
            display: false,
            align: 'right',
          })
          var year = yyyy + '年'
          if (!this.year_list.includes(year) && parseInt(yyyy) <= now_year) {
            this.year_list.push(year)
          }
        }
        this.headers.push({
          text: '12ヶ月 合計',
          value: 'total_max_amount',
          display: true,
          align: 'right',
        })

        this.change_display_setting()
      } catch (e) {
        console.log(e)
      }
    },
    change_display_setting() {
      try {
        if (this.display_year.length > 0) {
          var year = this.display_year
          var month = this.display_month

          var yyyy = year.slice(0, -1)
          var mm = ''
          if (month) {
            mm = month.slice(0, -1)
            if (mm.length == 1) {
              mm = '0' + mm
            }
          }
          var key = '_' + yyyy + '_' + mm
          var display_count = 0
          var fix_col_left = 2
          var fix_col_right = 1

          for (
            var i = fix_col_left;
            i < this.headers.length - fix_col_right;
            i++
          ) {
            this.headers[i].display = false
            if (display_count == 0 && this.headers[i].value.includes(key)) {
              this.headers[i].display = true
              display_count += 1
            } else if (display_count > 0 && display_count < 12) {
              this.headers[i].display = true
              display_count += 1
            }
          }
          this.display_changed += 1
        }
      } catch (e) {
        console.log(e)
      }
    },
    getTotalAmount() {
      try {
        this.calcTotalAmount(this.data_by_issue)
        this.calcTotalAmount(this.data_by_pay)
      } catch (e) {
        console.log(e)
      }
    },
    calcTotalAmount(data) {
      try {
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          item.total_max_amount = 0
          item.total_tax_amount = 0
          item.total_gensen_reward_amount = 0
          item.total_gensen_amount = 0
          item.total_pay_amount = 0
          item.total_unpaid_amount = 0
          for (let j = 2; j < this.headers.length - 1; j++) {
            let header = this.headers[j]
            if (header.display == true) {
              let date = header.value.slice(0, 8)
              item.total_max_amount += parseInt(item[date].max_amount)
              item.total_tax_amount += parseInt(item[date].tax_amount)
              item.total_gensen_reward_amount += parseInt(
                item[date].gensen_reward_amount
              )
              item.total_gensen_amount += parseInt(item[date].gensen_amount)
              item.total_pay_amount += parseInt(item[date].pay_amount)
              item.total_unpaid_amount += parseInt(item[date].unpaid_amount)
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    vueNotifyError: function (msg) {
      Snackbar.error(msg)
    },
    checkHaveGensenTargets(header) {
      if (header.total_gensen_targets > 0) {
        return ` (${header.total_gensen_targets}人)`
      } else {
        return ''
      }
    },
    toInvoiceList(company_name, date_key, flag) {
      if (flag) {
        //金額が0の時
        return
      }
      let yyyy = date_key.slice(1, 5)
      let mm = date_key.slice(6, 8)
      let monthLastDay = new Date(yyyy, mm, 0).getDate()
      let dateFrom = `${yyyy}-${mm}-01`
      let dateTo = `${yyyy}-${mm}-${monthLastDay}`

      const changeKeysPre = {
        company_name: company_name,
        headerDate: dateFrom,
        headerDateTo: dateTo,
      }

      if (this.date_type === 1) {
        var changeKeyAdd = {
          date: dateFrom,
          to_date: dateTo,
          filterDateType: 0,
        }
      } else {
        var changeKeyAdd = {
          pay_date_from: dateFrom,
          pay_date_to: dateTo,
          filterDateType: 2,
        }
      }
      //源泉税額、報酬額(源泉)を最優先で表示している時に源泉税のフィルタをかける
      if (
        this.amount_type == 'gensen_amount' ||
        this.amount_type == 'gensen_reward_amount'
      ) {
        changeKeyAdd.gensenzei = 1
      }

      let changeKeys = { ...changeKeysPre, ...changeKeyAdd }
      const searchForm = JSON.parse(localStorage.getItem('searchForm'))
      let newSearchForm = {}
      Object.keys(searchForm).forEach((key) => {
        newSearchForm[key] = ''
      })
      newSearchForm = { ...newSearchForm, ...changeKeys }
      localStorage.setItem('searchForm', JSON.stringify(newSearchForm))
      let url = location.origin + '/invoice'
      window.open(url, '_blank', 'width=1200,height=800')
    },
  },
}
</script>
